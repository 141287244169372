html {
  margin-right: calc(-1 * (100vw - 100%));
}

button:disabled {
  opacity: 0.5;
  cursor: unset;
}

.alignment-output {
  font-family: "IBM Plex Mono", monospace;
  font-size: 12px;
  white-space: pre-wrap;
}

p.alignment-line {
  margin: 0;
}

span.hmmminus {
  background: white;
}

span.hmmplus {
  background: #ccc;
}

span.hmmmatch {
  background: #999;
}

span.heatgap {
  background: white;
}

span.heat1 {
  background: #f4f4f4;
}

span.heat2 {
  background: #eaeaea;
}

span.heat3 {
  background: #e0e0e0;
}

span.heat4 {
  background: #d6d6d6;
}

span.heat5 {
  background: #ccc;
}

span.heat6 {
  background: #c1c1c1;
}

span.heat7 {
  background: #b7b7b7;
}

span.heat8 {
  background: #adadad;
}

span.heat9 {
  background: #a3a3a3;
}

span.heatstar {
  background: #999;
}

body {
  overflow-x: hidden;
}

.alignment-loading br {
   display: block;
   margin: 0;
}

.alignment-loading div
{
  font-family: "IBM Plex Mono", monospace;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 0;
  cursor:default !important;
  position:relative;
  display:inline-block;
  overflow:hidden;
  opacity:0.8;
}

.alignment-loading div.inline {
    display:inline;
}
.alignment-loading div:before{
    content:'';
    display:block;
    position:absolute;
    z-index:10;
    top:0;left:0;right:0;bottom:0;
    animation:2.2s ease forwards infinite placeHolderShimmer;
    background:#f2f2f2;
    background:linear-gradient(-61deg,#f2f2f2 40%,#E1E3E1 50%,#f2f2f2 60%);
    background-size:100vw 100%;
}
@keyframes placeHolderShimmer{
    0%{background-position:-50vw 0;}
    100%{background-position:50vw 0;}
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.midheight {
  min-height: 400px !important;
}
